import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardHeader,
  Label,
  FormGroup,
} from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const MapWrapper = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.445248, lng: 26.099672 }}
      defaultOptions={{
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#e9e9e9",
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 29,
              },
              {
                weight: 0.2,
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 18,
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#dedede",
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#ffffff",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: 36,
              },
              {
                color: "#333333",
              },
              {
                lightness: 40,
              },
            ],
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#f2f2f2",
              },
              {
                lightness: 19,
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#fefefe",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#fefefe",
              },
              {
                lightness: 17,
              },
              {
                weight: 1.2,
              },
            ],
          },
        ],
        scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
      }}
    >
      <Marker position={{ lat: 44.445248, lng: 26.099672 }} />
    </GoogleMap>
  ))
);

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <MultiDropdownNavbar />
      <LandingPageHeader />
      <div className="wrapper">
        <div className="section text-center landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <Element name="div-about" className="element">
                  <h2 className="title">About Us</h2>
                  <h3>Experience You Can Count On</h3>
                  <br/>
                  <h5>
                    Our service includes a comprehensive consultation to help identify gaps and  opportunities, a comprehensive report that includes a project plan with  timelines and milestones, a cost analysis, and a schedule. We also offer  a suite of quality products that will help you get there quickly and  smoothly. That’s how we ensure your success. 
                    <br/><br/>
                    We are well established and have earned good repute amongst our clients all due to proven digital solutions and in-depth understanding of online challenges. Our specialised solutions are known to bear a successful track record for our clients. 
                  </h5>
                </Element>
                {/* <br />
                <Button
                  className="btn-fill btn-round"
                  color="danger"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  See Details
                </Button> */}
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-palette" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Our Experience</h4>
                    <p className="description">
                      After 10 years in the industry, we decided to alter direction. Now, we share our passion by helping others. Our ramp up process is designed to empower your team.
                    </p>
                    {/* <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-bulb-63" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Why Us?</h4>
                    <p>
                      Business mentors are key—that’s why when it comes to client selection, we’re choosy. We want to give each of you the time and guidance you deserve.  
                    </p>
                    {/* <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
              {/* <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-chart-bar-32" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Statistics</h4>
                    <p>
                      Choose from a veriety of many colors resembling sugar
                      paper pastels.
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      See more
                    </Button>
                  </div>
                </div>
              </Col> */}
              <Col md="4">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-sun-fog-29" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Learn More</h4>
                    <p>
                      Our ramp up process is designed  to empower your team and outfit them with the tools they need to  succeed. Talk to us today about how we can support your growth, limit  your turnover, and put you on a solid track to success and profit.
                    </p>
                    {/* <Button
                      className="btn-link"
                      color="danger"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      See more
                    </Button> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-image text-center landing-section features-5"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/sections/the-how-photographer.jpg") +
            ")",
        }}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto" sm="12">
                <Element name="div-services" className="element">
                  <div className="ml-auto mr-auto">
                    <h2 className="title text-center">
                      Your life will be much easier with our Services
                    </h2>
                  </div>
                </Element>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-pin-3" />
                  </div>
                  <h3 className="title">Outsourcing</h3>
                  <p>
                    Our team has extensive experience in setting up call centers across the globe. We can assist you from a blank spot to a fully functional call center
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-planet" />
                  </div>
                  <h3 className="title">Telecommunication</h3>
                  <p>
                    Telecommunication services are a life line for any business and our team can ensure that you pay the lowest for this service, no matter how many services you have!
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-tv-2" />
                  </div>
                  <h3 className="title">Website Design and Development</h3>
                  <p>
                    Utilising various vehicles of website design and development we build a  robust UX or user experience. Developing a good user experience with  relation to your website our web marketing agency ensures that we put in  our professional and skilled designers to customize solutions for you.  Our designs are able to serve your digital needs and help you in  achieving your digital business goals. 
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon nc-time-alarm" />
                  </div>
                  <h3 className="title">Search Engine Optimization (SEO)</h3>
                  <p>
                  Business these days require to compete on virtual platform, it is essential that any business that looks forward to grow exponentially needs digital marketing strategy. With our internet marketing service we provide a well charted out approach towards SEO or Search Engine Optimisation for your business online. 
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-contactus cd-section" id="contact-us">
        
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="12">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    <Element name="div-contact-us" className="element">
                      Contact Us
                    </Element>
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto mr-auto text-center" md="6">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-info">
                            <i className="nc-icon nc-pin-3" />
                          </div>
                          <div className="description">
                            <h4 className="info-title">
                              Find us at the office
                            </h4>
                            <p>
                              850, Collins Street, Docklands
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    <Col className="ml-auto mr-auto text-center" md="6">
                      <CardBody>
                        <div className="info info-horizontal">
                          <div className="icon icon-danger">
                            <i className="nc-icon nc-badge" />
                          </div>
                          <div className="description black">
                            <h4 className="info-title">Give us a ring</h4>
                            <p>
                              GC Consultancy <br />
                              03 8747 1225
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    {/* <Col className="mr-auto" md="5">
                      <Form id="contact-form" role="form">
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  First name
                                </label>
                                <Input
                                  name="name"
                                  placeholder="First Name"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="label-floating">
                                <label className="control-label">
                                  Last name
                                </label>
                                <Input
                                  name="name"
                                  placeholder="Last Name"
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Email address
                            </label>
                            <Input
                              name="email"
                              placeholder="Email"
                              type="email"
                            />
                          </FormGroup>
                          <FormGroup className="label-floating">
                            <label className="control-label">
                              Your message
                            </label>
                            <Input
                              id="message"
                              name="message"
                              placeholder="Message"
                              type="textarea"
                              rows="6"
                            />
                          </FormGroup>
                          <Row>
                            <Col md="6">
                              <FormGroup check>
                                <Label check>
                                  <Input defaultValue="" type="checkbox" />
                                  I'm not a robot !{" "}
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <Button
                                className="pull-right"
                                color="primary"
                                type="submit"
                              >
                                Send Message
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Form>
                    </Col> */}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default LandingPage;
