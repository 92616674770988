import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("assets/img/gc/header-img.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">Experienced, Personal Consulting</h1>
              <h2 className="description">
                Helping you design success!
              </h2>
              <br />
              <ScrollLink to="div-contact-us" spy={true} smooth={true} offset={50} duration={500}>
              <Button
                className="btn-round mr-1 btn-lg"
                color="neutral"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ?ref=creativetim"
                target="_blank"
              >
                <i className="fa fa-lg fa-phone" />{" "}
                Contact Us
              </Button>
                  </ScrollLink>
              
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
